.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color .3s;
}

.column-name{
  display: none;
}

@media(max-width: 768px){
  .ant-table-thead > tr {
      display: none;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr {
      display: block;
  }
  .ant-table-thead > tr > th, .ant-table-tbody > tr > td{
      display: block;
      width: auto !important;
      border-bottom: 0px;
  }

  .ant-table-tbody > tr > td:last-child {
      border-bottom: 1px solid #eee;
  }
  
  .ant-table-tbody > tr > td:first-of-type {
      padding-top: 1rem;
  }

  .ant-table-tbody > tr > td:last-of-type {
      padding-top: 1rem;
  }

  .column-name {
    display: block;
  }
}