.customer-page-content {
    padding: 0em 1em;
}

.customer-page-content .ant-tabs-bottom-bar {
    background-color: white;
}

.mobile-menu {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    visibility: hidden;
}

@media screen and (max-width: 992px){
    .customer-page-content {
        padding: 0em;
        margin-bottom: 55px;
    }
    .mobile-menu {
        visibility: visible;
    }
}