.editable-text-value-wrap {
  min-height: 32px;
  padding: 4px 11px;
  border: 1px solid var(--chakra-colors-gray-100);
  border-radius: 2px;
  cursor: pointer;
}

.editable-text-value-wrap:hover {
  border: 1px solid var(--chakra-colors-gray-300);
}
