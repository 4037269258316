.thumbnail-col {
  width: 40px;
}

.thumnbail-menu-item {
  padding-left: 65px !important;
}

.menu-item-product-col {
  width: 300px;
  max-width: 300px;
}

.menu-item-status-col {
  width: 125px;
  max-width: 125px;
}

.menu-item-avail-col {
  width: 200px;
  max-width: 200px;
}

.menu-item-price-col {
  width: 100px;
  max-width: 100px;
}
